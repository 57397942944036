import axios from "axios";
import useAuthAxios from '../utility/useAuthAxios';


// api.js
export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const authToken = localStorage.getItem("token");

const handleApiCall = async (endpoint, method = "GET", data = {}) => {
  const requestOptions = {
    method,
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (method !== "GET") {
    requestOptions.body = JSON.stringify(data);
  }

  const response = await fetch(`${apiBaseUrl}/${endpoint}`, requestOptions);
  const result = await response.json();
  return result;
};

export default handleApiCall;

// 6666666666666

const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${authToken}`,
  },
};

export const getAllUser = async () => {
  try {
    let { data } = await useAuthAxios.get(`${apiBaseUrl}/allusers`);
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const getProductTypes = async () => {
  const response = await axios.get(`${apiBaseUrl}/getAllProductTypesForAdmin`);
  return response.data;
};

export const getProductsByCategory = async (id) => {
  const response = await axios.get(`${apiBaseUrl}/getProductsByCategory?id=${id}`);
  return response.data;
};

export const deleteProduct = async (productId) => {
  const response = await axios.delete(`${apiBaseUrl}/deleteProduct?id=${productId}`);
  return response.data;
};

export const addProductType = async (productType) => {
  return await axios.post(`${apiBaseUrl}/addProductType`, productType, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const addProduct = async (product) => {
  return await axios.post(`${apiBaseUrl}/addProduct`, product, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

