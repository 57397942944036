import React, { useState, useEffect } from "react";
import {
    Container,
    Grid,
    Typography,
    Button,
    Modal,
    TextField,
    Card,
    CardContent,
    CardMedia,
    Box,
    IconButton,
    Snackbar,
    Alert,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import axios from 'axios';
import { apiBaseUrl } from './Config';
import useAuthAxios from '../utility/useAuthAxios';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ProductsCategory = () => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [formData, setFormData] = useState({
        productTypeTitle: "",
        productTypeShortName: "",
        productTypeImgPath: null,
        productTypeDesc: "",
        productTypeCtgImg: null,
        productTypeCtgDesc: ""
    });
    const [openAddModal, setOpenAddModal] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [categoryToDelete, setCategoryToDelete] = useState(null);
    const authAxios = useAuthAxios();

    const fetchCategories = async () => {
        try {
            const response = await authAxios.get(`${apiBaseUrl}/getProductCategories`);
            setCategories(response.data);
        } catch (error) {
            showAlert('Error fetching categories', 'error');
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        fetchCategories();
    }, []);
    const handleEditClick = (category) => {
        setSelectedCategory(category);
        console.log(category);
        setFormData({
            productTypeTitle: category.productTypeTitle,
            productTypeShortName: category.productTypeShortName,
            productTypeImgPath: null,
            productTypeDesc: category.productTypeDesc,
            productTypeCtgImg: null,
            productTypeCtgDesc: category.productTypeCtgDesc
        });
        setOpenModal(true);
    };

    const handleFormChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({ ...formData, [name]: files ? files[0] : value });
    };

    const handleFormSubmit = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('productTypeId', selectedCategory.productTypeId);
            formDataToSend.append('productTypeTitle', formData.productTypeTitle);
            formDataToSend.append('productTypeShortName', formData.productTypeShortName);
            formDataToSend.append('productTypeImgPath', formData.productTypeImgPath);
            formDataToSend.append('productTypeDesc', formData.productTypeDesc);
            formDataToSend.append('productTypeCtgImg', formData.productTypeCtgImg);
            formDataToSend.append('productTypeCtgDesc', formData.productTypeCtgDesc);

            await axios.put(`${apiBaseUrl}/updateProductCategory`, formDataToSend);
            setOpenModal(false);
            fetchCategories();
            showAlert('Category updated successfully', 'success');
        } catch (error) {
            showAlert('Error updating category' + error, 'error');
        }
    };

    const handleAddClick = () => {
        setFormData({
            productTypeTitle: "",
            productTypeShortName: "",
            productTypeImgPath: null,
            productTypeDesc: "",
            productTypeCtgImg: null,
            productTypeCtgDesc: ""
        });
        setOpenAddModal(true);
    };

    const handleAddFormSubmit = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('productTypeTitle', formData.productTypeTitle);
            formDataToSend.append('productTypeShortName', formData.productTypeShortName);
            formDataToSend.append('productTypeImgPath', formData.productTypeImgPath);
            formDataToSend.append('productTypeDesc', formData.productTypeDesc);
            formDataToSend.append('productTypeCtgImg', formData.productTypeCtgImg);
            formDataToSend.append('productTypeCtgDesc', formData.productTypeCtgDesc);

            await axios.post(`${apiBaseUrl}/addProductCategory`, formDataToSend);
            setOpenAddModal(false);
            fetchCategories();
            showAlert('Category added successfully', 'success');
        } catch (error) {
            showAlert('Error adding category', 'error');
        }
    };

    const handleDeleteClick = (category) => {
        setCategoryToDelete(category);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            await axios.delete(`${apiBaseUrl}/deleteProductCategory/${categoryToDelete.id}`);
            setDeleteDialogOpen(false);
            fetchCategories();
            showAlert('Category deleted successfully', 'success');
        } catch (error) {
            showAlert('Error deleting category', 'error');
        }
    };

    const showAlert = (message, severity) => {
        setAlertMessage(message);
        setAlertSeverity(severity);
        setAlertOpen(true);
    };

    const isFormValid = () => {
        return formData.productTypeTitle &&
            formData.productTypeShortName &&
            formData.productTypeDesc &&
            formData.productTypeCtgDesc &&
            formData.productTypeImgPath &&
            formData.productTypeCtgImg;
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
                <Typography variant="h4">Product Categories</Typography>
                <Button variant="contained" color="primary" onClick={handleAddClick} startIcon={<AddIcon />}>
                    Add Category
                </Button>
            </Box>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Grid container spacing={4}>
                    {categories.length > 0 ? categories.map((category) => (
                        <Grid item key={category.productTypeId} xs={12} sm={6} md={4}>
                            <Card>
                                <CardMedia
                                    component="img"
                                    height="160"
                                    image={category.productTypeCtgImg}
                                    alt={category.productTypeTitle}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {category.productTypeTitle}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {category.productTypeShortName}
                                    </Typography>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                        <IconButton
                                            color="primary"
                                            onClick={() => handleEditClick(category)}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        {/* <IconButton
                                            color="error"
                                            onClick={() => handleDeleteClick(category)}
                                        >
                                            <DeleteIcon />
                                        </IconButton> */}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    )) : <p>loading...</p>}
                </Grid>
            )}

            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={modalStyle}>
                    <IconButton
                        sx={{ position: 'absolute', top: 8, right: 8 }}
                        onClick={() => setOpenModal(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography id="modal-title" variant="h6" component="h2">
                        Edit Category
                    </Typography>
                    <TextField
                        margin="normal"
                        fullWidth
                        id="productTypeTitle"
                        name="productTypeTitle"
                        label="Product Type Title"
                        value={formData.productTypeTitle}
                        onChange={handleFormChange}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        id="productTypeShortName"
                        name="productTypeShortName"
                        label="Product Type Short Name"
                        value={formData.productTypeShortName}
                        onChange={handleFormChange}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        id="productTypeDesc"
                        name="productTypeDesc"
                        label="Product Type Description"
                        value={formData.productTypeDesc}
                        onChange={handleFormChange}
                        multiline
                        rows={6}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        id="productTypeCtgDesc"
                        name="productTypeCtgDesc"
                        label="Product Type Category Description"
                        value={formData.productTypeCtgDesc}
                        onChange={handleFormChange}
                    />
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="body2" sx={{ display: 'inline-block', width: '120px' }}>Main Image:</Typography>
                        <input
                            accept="image/*"
                            id="productTypeImgPath"
                            name="productTypeImgPath"
                            type="file"
                            onChange={handleFormChange}
                            style={{ display: 'inline-block' }}
                        />
                    </Box>
                    <Box sx={{ mt: 2, mb: 3 }}>
                        <Typography variant="body2" sx={{ display: 'inline-block', width: '120px' }}>Catalog Image:</Typography>
                        <input
                            accept="image/*"
                            id="productTypeCtgImg"
                            name="productTypeCtgImg"
                            type="file"
                            onChange={handleFormChange}
                            style={{ display: 'inline-block' }}
                        />
                    </Box>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleFormSubmit}
                        disabled={!isFormValid()}
                    >
                        Update Category
                    </Button>
                </Box>
            </Modal>

            <Modal
                open={openAddModal}
                onClose={() => setOpenAddModal(false)}
                aria-labelledby="add-modal-title"
                aria-describedby="add-modal-description"
            >
                <Box sx={modalStyle}>
                    <IconButton
                        sx={{ position: 'absolute', top: 8, right: 8 }}
                        onClick={() => setOpenAddModal(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography id="add-modal-title" variant="h6" component="h2">
                        Add Category
                    </Typography>
                    <TextField
                        margin="normal"
                        fullWidth
                        id="productTypeTitle"
                        name="productTypeTitle"
                        label="Product Type Title"
                        value={formData.productTypeTitle}
                        onChange={handleFormChange}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        id="productTypeShortName"
                        name="productTypeShortName"
                        label="Product Type Short Name"
                        value={formData.productTypeShortName}
                        onChange={handleFormChange}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        id="productTypeDesc"
                        name="productTypeDesc"
                        label="Product Type Description"
                        value={formData.productTypeDesc}
                        onChange={handleFormChange}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        id="productTypeCtgDesc"
                        name="productTypeCtgDesc"
                        label="Product Type Category Description"
                        value={formData.productTypeCtgDesc}
                        onChange={handleFormChange}
                    />
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="body2" sx={{ display: 'inline-block', width: '120px' }}>Main Image:</Typography>
                        <input
                            accept="image/*"
                            id="productTypeImgPath"
                            name="productTypeImgPath"
                            type="file"
                            onChange={handleFormChange}
                            style={{ display: 'inline-block' }}
                        />
                    </Box>
                    <Box sx={{ mt: 2, mb: 3 }}>
                        <Typography variant="body2" sx={{ display: 'inline-block', width: '120px' }}>Catalog Image:</Typography>
                        <input
                            accept="image/*"
                            id="productTypeCtgImg"
                            name="productTypeCtgImg"
                            type="file"
                            onChange={handleFormChange}
                            style={{ display: 'inline-block' }}
                        />
                    </Box>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleAddFormSubmit}
                        disabled={!isFormValid()}
                    >
                        Add Category
                    </Button>
                </Box>
            </Modal>

            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
            >
                <DialogTitle>{"Confirm Delete"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this category?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={() => setAlertOpen(false)}>
                <Alert onClose={() => setAlertOpen(false)} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default ProductsCategory;