import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const useAuthAxios = () => {
    const navigate = useNavigate();

    const authAxios = axios.create({
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    authAxios.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response && error.response.status === 401) {
                alert('Session expired. Please log in again.');
                navigate('/login');
            }
            return Promise.reject(error);
        }
    );

    return authAxios;
};

export default useAuthAxios;
