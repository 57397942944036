import React, { useState, useEffect } from "react";
import {
    Container,
    Grid,
    Typography,
    Button,
    Modal,
    TextField,
    Box,
    IconButton,
    Snackbar,
    Alert,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Card,
    CardContent,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import axios from 'axios';
import { apiBaseUrl } from './Config';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const GalleryComponent = () => {
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [formData, setFormData] = useState({
        gImageTooltip: "",
        gImagePath: null
    });
    const [openAddModal, setOpenAddModal] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [imageToDelete, setImageToDelete] = useState(null);

    useEffect(() => {
        fetchImages();
    }, []);

    const fetchImages = async () => {
        try {
            const response = await axios.get(`${apiBaseUrl}/getGalleryImages`);
            setImages(response.data);
        } catch (error) {
            showAlert('Error fetching images', 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleEditClick = (image) => {
        setSelectedImage(image);
        setFormData({
            gImageTooltip: image.gImageTooltip,
            gImagePath: null
        });
        setOpenModal(true);
    };

    const handleFormChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({ ...formData, [name]: files ? files[0] : value });
    };

    const handleFormSubmit = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('gId', selectedImage.gId);
            formDataToSend.append('gImageTooltip', formData.gImageTooltip);
            if (formData.gImagePath) {
                formDataToSend.append('gImagePath', formData.gImagePath);
            }

            await axios.put(`${apiBaseUrl}/updateGalleryImage`, formDataToSend);
            setOpenModal(false);
            fetchImages();
            showAlert('Image updated successfully', 'success');
        } catch (error) {
            showAlert('Error updating image', 'error');
        }
    };

    const handleAddClick = () => {
        setFormData({
            gImageTooltip: "",
            gImagePath: null
        });
        setOpenAddModal(true);
    };

    const handleAddFormSubmit = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('gImageTooltip', formData.gImageTooltip);
            formDataToSend.append('gImagePath', formData.gImagePath);

            await axios.post(`${apiBaseUrl}/addGalleryImage`, formDataToSend);
            setOpenAddModal(false);
            fetchImages();
            showAlert('Image added successfully', 'success');
        } catch (error) {
            showAlert('Error adding image', 'error');
        }
    };

    const handleDeleteClick = (image) => {
        setImageToDelete(image);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            await axios.delete(`${apiBaseUrl}/deleteGalleryImage?id=${imageToDelete.gId}`);
            setDeleteDialogOpen(false);
            fetchImages();
            showAlert('Image deleted successfully', 'success');
        } catch (error) {
            showAlert('Error deleting image', 'error');
        }
    };

    const showAlert = (message, severity) => {
        setAlertMessage(message);
        setAlertSeverity(severity);
        setAlertOpen(true);
    };

    const isFormValid = () => {
        return formData.gImageTooltip && (formData.gImagePath || selectedImage);
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
                <Typography variant="h4">Gallery</Typography>
                <Button variant="contained" color="primary" onClick={handleAddClick} startIcon={<AddIcon />}>
                    Add Image
                </Button>
            </Box>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Grid container spacing={4}>
                    {console.log(images)}
                    {images.map((image) => (
                        <Grid item key={image.gId} xs={12} sm={6} md={4}>
                            <Card>
                                <img src={image.gImagePath} alt={image.gImageTooltip} width="100%" height="160" style={{ objectFit: "cover" }} />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {image.gImageTooltip}
                                    </Typography>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                        <IconButton
                                            color="primary"
                                            onClick={() => handleEditClick(image)}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            color="error"
                                            onClick={() => handleDeleteClick(image)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            )}

            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={modalStyle}>
                    <IconButton
                        sx={{ position: 'absolute', top: 8, right: 8 }}
                        onClick={() => setOpenModal(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography id="modal-title" variant="h6" component="h2">
                        Edit Image
                    </Typography>
                    <TextField
                        margin="normal"
                        fullWidth
                        id="gImageTooltip"
                        name="gImageTooltip"
                        label="Image Tooltip"
                        value={formData.gImageTooltip}
                        onChange={handleFormChange}
                    />
                    <Typography sx={{ fontSize: 14 }}>Image</Typography>
                    <input
                        accept="image/*"
                        id="gImagePath"
                        name="gImagePath"
                        type="file"
                        onChange={handleFormChange}
                        style={{ display: 'block', margin: '20px 0' }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleFormSubmit}
                        disabled={!isFormValid()}
                    >
                        Update Image
                    </Button>
                </Box>
            </Modal>

            <Modal
                open={openAddModal}
                onClose={() => setOpenAddModal(false)}
                aria-labelledby="add-modal-title"
                aria-describedby="add-modal-description"
            >
                <Box sx={modalStyle}>
                    <IconButton
                        sx={{ position: 'absolute', top: 8, right: 8 }}
                        onClick={() => setOpenAddModal(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography id="add-modal-title" variant="h6" component="h2">
                        Add Image
                    </Typography>
                    <TextField
                        margin="normal"
                        fullWidth
                        id="gImageTooltip"
                        name="gImageTooltip"
                        label="Image Tooltip"
                        value={formData.gImageTooltip}
                        onChange={handleFormChange}
                    />
                    <Typography sx={{ fontSize: 14 }}>Image</Typography>
                    <input
                        accept="image/*"
                        id="gImagePath"
                        name="gImagePath"
                        type="file"
                        onChange={handleFormChange}
                        style={{ display: 'block', margin: '20px 0' }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleAddFormSubmit}
                        disabled={!isFormValid()}
                    >
                        Add Image
                    </Button>
                </Box>
            </Modal>

            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
            >
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this image?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handleDeleteConfirm} color="error">Delete</Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={() => setAlertOpen(false)}
            >
                <Alert
                    onClose={() => setAlertOpen(false)}
                    severity={alertSeverity}
                    sx={{ width: '100%' }}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default GalleryComponent;