import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Snackbar, Alert, Container, Typography, CircularProgress } from '@mui/material';
import { apiBaseUrl } from './Config'
const AboutUsForm = () => {
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({ sectionTitle: '', sectionDesc: '', sectionId: '' });
    const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });

    useEffect(() => {
        // Fetch data from API
        axios.get(`${apiBaseUrl}/getSectionDetails/1`)
            .then(response => {
                console.log(response.data.data);
                setFormData(response.data.data); // Assuming the response structure has data.data
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setNotification({ open: true, message: 'Error fetching data', severity: 'error' });
            });
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Update data via API
        console.log(formData.data);
        axios.put(`${apiBaseUrl}/setSectionDesc`, { sectionTitle: formData.sectionTitle, sectionDesc: formData.sectionDesc, sectionId: 1 })
            .then(response => {
                setNotification({ open: true, message: 'Data updated successfully', severity: 'success' });
                // Optionally, refetch the updated data
                return axios.get(`${apiBaseUrl}/getSectionDetails/1`);
            })
            .then(response => {
                setFormData(response.data.data); // Assuming the response structure has data.data
            })
            .catch(error => {
                console.error('Error updating data:', error);
                setNotification({ open: true, message: 'Error updating data', severity: 'error' });
            });
    };

    const handleClose = () => {
        setNotification({ ...notification, open: false });
    };

    return (
        <Container>
            <Typography variant="h4" component="h1" gutterBottom>{formData.sectionTitle}</Typography>
            {loading ? (
                <CircularProgress />
            ) : (
                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Title"
                        disabled={true}
                        placeholder="Title"
                        name="sectionTitle"
                        value={formData.sectionTitle}
                        onChange={handleChange}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Description"
                        name="sectionDesc"
                        placeholder="Description"
                        value={formData.sectionDesc}
                        onChange={handleChange}
                        multiline
                        rows={6}
                    />
                    <Button variant="contained" color="primary" type="submit">Update</Button>
                </form>
            )}
            <Snackbar open={notification.open} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={notification.severity} sx={{ width: '100%' }}>
                    {notification.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default AboutUsForm;
