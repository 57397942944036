import React, { useEffect, useContext } from 'react'
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { UserContext } from '../App';
import { apiBaseUrl } from './Config';


const Logout = () => {
    const { state, dispatch } = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`${apiBaseUrl}/logout`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            withCredentials: true
        }).then((res) => {
            dispatch({ type: "USER", payload: false });
            navigate('/login');
        }).catch((error) => {
            console.log(error);
        });
    }, [dispatch, navigate]);

    return (
        <div>Logout</div>
    );
}

export default Logout;
