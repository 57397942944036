import React, { useState, useEffect } from "react";
import {
    Box,
    Container,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography,
    CircularProgress,
    Checkbox,
    Button,
    Alert,
    Snackbar
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import axios from "axios";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { apiBaseUrl } from "./Config";

const defaultTheme = createTheme();

const columns = [
    { id: 'selectId', label: 'Select', align: 'center' },
    { id: 'userName', label: 'Username', align: 'left' },
    { id: 'reviewDescription', label: 'Review Description', align: 'left' },
    { id: 'status', label: 'Status', align: 'left' },
    { id: 'rating', label: 'Rating', align: 'right' },
    { id: 'postDate', label: 'Post Date', align: 'left' },
];

const Reviews = () => {
    const [reviews, setReviews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('rating');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [selectedIds, setSelectedIds] = useState([]);
    const [alertOpen, setAlertOpen] = useState(false);

    useEffect(() => {
        fetchReviews();
    }, []);

    const fetchReviews = async () => {
        try {
            const response = await axios.get(`${apiBaseUrl}/getFeedbacksForAdminDashboard?status=901,902&column=fbkPostDate&order=desc`); // Replace with your API endpoint
            setReviews(response.data.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching reviews:', error);
            setLoading(false);
        }
    };

    const handleSortRequest = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedReviews = () => {
        const comparator = (a, b) => {
            if (orderBy === 'rating') {
                return (order === 'asc' ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy]);
            } else {
                return (order === 'asc' ? (a[orderBy] > b[orderBy] ? 1 : -1) : (b[orderBy] > a[orderBy] ? 1 : -1));
            }
        };
        return reviews?.sort(comparator);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, reviews?.length - page * rowsPerPage);

    const handleCheckboxChange = (event, id) => {
        setSelectedIds(prevSelectedIds =>
            event.target.checked
                ? [...prevSelectedIds, id]
                : prevSelectedIds.filter(selectedId => selectedId !== id)
        );
    };

    const handleUpdate = async () => {
        try {
            // Create an array of objects with fbkId and the new status
            const updatedStatuses = selectedIds.map(id => {
                const review = reviews.find(review => review.fbkId === id);
                const newStatus = review.activeFlag === 901 ? 902 : 901;
                return { fbkId: id, status: newStatus };
            });

            // Replace with your API endpoint for update
            const response = await axios.put(`${apiBaseUrl}/updateFeedbackFlag`, { updatedStatuses });
            console.log('Update response:', response.data);

            // Show alert
            setAlertOpen(true);

            // Refetch the data after update
            await fetchReviews();

            // Optionally clear the selected IDs
            setSelectedIds([]);
        } catch (error) {
            console.error('Error updating reviews:', error);
        }
    };

    const handleAlertClose = () => {
        setAlertOpen(false);
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Typography variant="h4" gutterBottom>
                    Reviews
                </Typography>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                        >
                                            <TableSortLabel
                                                active={orderBy === column.id}
                                                direction={orderBy === column.id ? order : 'asc'}
                                                onClick={() => handleSortRequest(column.id)}
                                            >
                                                {column.label}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedReviews()
                                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    ?.map((review, index) => (
                                        <TableRow key={review.fbkId}>
                                            <TableCell align="center">
                                                <Checkbox
                                                    checked={selectedIds.includes(review.fbkId)}
                                                    onChange={(event) => handleCheckboxChange(event, review.fbkId)}
                                                />
                                            </TableCell>
                                            <TableCell align="left">{review.fbkUserName}</TableCell>
                                            <TableCell align="left">{review.fbkReviewDesc}</TableCell>
                                            <TableCell align="left">
                                                {review.activeFlag === 901 ? <CheckIcon color="success" /> : <CloseIcon color="error" />}
                                            </TableCell>
                                            <TableCell align="right">{review.fbkStarCount}</TableCell>
                                            <TableCell align="left">{review.fbkPostDate}</TableCell>
                                        </TableRow>
                                    ))}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleUpdate}
                                disabled={selectedIds.length === 0}
                            >
                                Update Reviews
                            </Button>
                        </Box>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15]}
                            component="div"
                            count={reviews?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                )}
                <Snackbar
                    open={alertOpen}
                    autoHideDuration={3000}
                    onClose={handleAlertClose}
                >
                    <Alert onClose={handleAlertClose} severity="success" sx={{ width: '100%' }}>
                        Review statuses have been updated successfully!
                    </Alert>
                </Snackbar>
            </Container>
        </ThemeProvider>
    );
};

export default Reviews;
