import React, { useState, useContext } from "react";
import styled from "styled-components";
import logo from "../assets/logo.png";
import rightImage from "../assets/right.jpeg";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import axios from "axios";
import { apiBaseUrl } from './Config';
import { UserContext } from '../App';

// import handleApiCall from "./Config";


let currentDate = new Date();
let currentYear = currentDate.getFullYear();

const Container = styled.div`
  display: flex;
  height: 100vh;
`;

const LeftContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    display: none; /* Hide on mobile */
  }
`;

const RightContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormContainer = styled.div`
  max-width: 400px;
  width: 100%;
  margin-top: -50px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
`;

const Form = styled.form`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: -150px;
  text-align: center; /* Center text horizontally */
`;
const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
`;

const RightImage = styled.img`
  width: 100%;
  justify-content: center;
  border-top-right-radius: 30%;
`;

const ImageAboveForm = styled.img`
  max-width: 100%;
  height: 100%;
`;

const SpanText = styled.span`
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
`;

const Login = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      const response = await axios.post(`${apiBaseUrl}/signin`, { email, password }, {
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true,
      });
      console.log(response);
      if (response.data.success) {
        // Redirect to a protected route after successful login
        dispatch({ type: "USER", payload: true });
        navigate("/dashboard");
      }
    } catch (err) {
      if (err.response) {
        // Server responded with a status other than 200 range
        setError(err.response.data.error);
      } else {
        // Network error or some other reason
        setError('An error occurred. Please try again.');
      }
    }

    // const result = await handleApiCall("signin", "POST", { email, password });

    // localStorage.setItem("token", result.token);
  };

  return (
    <Container>
      <LeftContainer>
        <RightImage src={rightImage} alt="Left Image" />
      </LeftContainer>
      <RightContainer>
        <FormContainer>
          <ImageAboveForm src={logo} alt="Above Form Image" />
          <Form onSubmit={handleSubmit}>
            <h2>Login To Dashboard</h2>
            <Input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <Input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <Button style={{ width: "30%" }} type="submit" variant="outlined">
              Login
            </Button>
          </Form>
          <SpanText>Copyright © Damodarr {currentYear}.</SpanText>
        </FormContainer>
      </RightContainer>
    </Container>
  );
};

export default Login;
