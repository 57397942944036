import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    Box,
    Container,
    Grid,
    Paper,
    Typography,
    Button,
    Modal,
    TextField,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Snackbar,
    Alert,
    CircularProgress
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { getProductTypes, getProductsByCategory, apiBaseUrl, deleteProduct, addProductType, addProduct } from './Config'; // Assume these API functions are defined in api.js

const defaultTheme = createTheme();

const ModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Products = () => {
    const [productTypes, setProductTypes] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const [selectedType, setSelectedType] = useState(null);
    const [products, setProducts] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [formData, setFormData] = useState({ productName: "", productImg: null });
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [deleteProductId, setDeleteProductId] = useState(null);
    const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });
    const [categoryFormData, setCategoryFormData] = useState({ productTypeTitle: "", productTypeDesc: "", productTypeImgPath: null });
    const [openAddProductModal, setOpenAddProductModal] = useState(false);
    const [newProductFormData, setNewProductFormData] = useState({ productName: "", productImgPath: null });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchProductTypes().then(response => {
            setLoading(false);
        });
    }, []);

    const fetchProductTypes = async () => {
        const types = await getProductTypes();
        setProductTypes(types.data);
    };

    const fetchProducts = async (id) => {
        const products = await getProductsByCategory(id);
        setProducts(products.data);
        setSelectedType(id);
    };

    const handleAccordionChange = (type) => (event, isExpanded) => {
        setExpanded(isExpanded ? type.productTypeId : false);
        if (isExpanded) {
            fetchProducts(type.productTypeId);
        }
    };

    const handleEditClick = (product) => {
        setSelectedProduct(product);
        setFormData({
            productName: product.productName,
            productImg: null
        });
        setOpenModal(true);
    };

    const handleDeleteClick = (productId) => {
        setDeleteProductId(productId);
        setConfirmDeleteOpen(true);
    };

    const handleConfirmDelete = async () => {
        await deleteProduct(deleteProductId);
        setConfirmDeleteOpen(false);
        fetchProducts(selectedType);
        setNotification({ open: true, message: 'Product deleted successfully', severity: 'success' });
    };

    const handleCancelDelete = () => {
        setConfirmDeleteOpen(false);
        setDeleteProductId(null);
    };

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData({ ...formData, productImg: file });
    };

    const handleFormSubmit = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('productName', formData.productName);
        formDataToSend.append('productImg', formData.productImg);
        formDataToSend.append('productId', selectedProduct.productId);

        await axios.put(`${apiBaseUrl}/updateProduct`, formDataToSend, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        setOpenModal(false);
        fetchProducts(selectedType);
        setNotification({ open: true, message: 'Product updated successfully', severity: 'success' });
    };

    const handleCategoryFormChange = (e) => {
        const { name, value } = e.target;
        setCategoryFormData({ ...categoryFormData, [name]: value });
    };

    const handleCategoryFileChange = (e) => {
        const file = e.target.files[0];
        setCategoryFormData({ ...categoryFormData, productTypeImgPath: file });
    };

    const handleAddProductClick = (typeId) => {
        setSelectedType(typeId);
        setNewProductFormData({ productName: "", productImgPath: null });
        setOpenAddProductModal(true);
    };

    const handleNewProductFormChange = (e) => {
        const { name, value } = e.target;
        setNewProductFormData({ ...newProductFormData, [name]: value });
    };

    const handleNewProductFileChange = (e) => {
        const file = e.target.files[0];
        setNewProductFormData({ ...newProductFormData, productImgPath: file });
    };

    const handleAddProductSubmit = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('productName', newProductFormData.productName);
        formDataToSend.append('productImgPath', newProductFormData.productImgPath);
        formDataToSend.append('productTypeId', selectedType);

        await addProduct(formDataToSend);

        setOpenAddProductModal(false);
        fetchProducts(selectedType);
        setNotification({ open: true, message: 'Product added successfully', severity: 'success' });
    };

    const handleCloseNotification = () => {
        setNotification({ open: false, message: '', severity: 'success' });
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom>Products</Typography>
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    productTypes && productTypes.map((type) => (
                        <Accordion
                            key={type.productTypeId}
                            expanded={expanded === type.productTypeId}
                            onChange={handleAccordionChange(type)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>{type.productTypeTitle} ({type.productCount})</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {expanded === type.productTypeId && type.productCount > 0 ? (
                                    <Grid container spacing={3} sx={{ mt: 2 }}>
                                        {products && products.map((product) => (
                                            <Grid item xs={12} key={product.productId}>
                                                <Paper sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
                                                    <Typography>{product.productName}</Typography>
                                                    <Box>
                                                        <IconButton
                                                            color="primary"
                                                            sx={{ mr: 1 }}
                                                            onClick={() => handleEditClick(product)}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                        <IconButton
                                                            color="error"
                                                            onClick={() => handleDeleteClick(product.productId)}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Box>
                                                </Paper>
                                            </Grid>
                                        ))}
                                    </Grid>
                                ) : (<Typography sx={{ fontSize: 14 }}>No products to show.</Typography>)}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ mt: 2 }}
                                    onClick={() => handleAddProductClick(type.productTypeId)}
                                    startIcon={<AddIcon />}
                                >
                                    Add Product
                                </Button>
                            </AccordionDetails>
                        </Accordion>
                    ))
                )}
                < Modal
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Box sx={ModalStyle}>
                        <Typography id="modal-title" variant="h6" component="h2">
                            Edit Product
                        </Typography>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="productName"
                            name="productName"
                            value={formData.productName}
                            onChange={handleFormChange}
                        />
                        <Typography>
                            Upload Image
                        </Typography>
                        <input
                            type="file"
                            id="docpicker"
                            accept=".png,.jpg,.jpeg"
                            name="productImg"
                            onChange={handleFileChange}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ mt: 2 }}
                            onClick={handleFormSubmit}
                            disabled={!formData.productName || !formData.productImg}
                        >
                            Save
                        </Button>
                    </Box>
                </Modal>

                <Modal
                    open={openAddProductModal}
                    onClose={() => setOpenAddProductModal(false)}
                    aria-labelledby="add-product-modal-title"
                    aria-describedby="add-product-modal-description"
                >
                    <Box sx={ModalStyle}>
                        <Typography id="add-product-modal-title" variant="h6" component="h2">
                            Add Product
                        </Typography>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="productName"
                            name="productName"
                            label="Product Name"
                            value={newProductFormData.productName}
                            onChange={handleNewProductFormChange}
                        />
                        <Typography>
                            Upload Image
                        </Typography>
                        <input
                            type="file"
                            id="docpicker"
                            accept=".png,.jpg,.jpeg"
                            onChange={handleNewProductFileChange}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ mt: 2 }}
                            onClick={handleAddProductSubmit}
                            disabled={!newProductFormData.productName || !newProductFormData.productImgPath}
                        >
                            Save
                        </Button>
                    </Box>
                </Modal>

                <Dialog
                    open={confirmDeleteOpen}
                    onClose={handleCancelDelete}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this product?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancelDelete} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleConfirmDelete} color="error" autoFocus>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>

                <Snackbar open={notification.open} autoHideDuration={3000} onClose={handleCloseNotification}>
                    <Alert onClose={handleCloseNotification} severity={notification.severity} sx={{ width: '100%' }}>
                        {notification.message}
                    </Alert>
                </Snackbar>
            </Container>
        </ThemeProvider >
    );
};

export default Products;
