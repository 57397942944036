import React, { useState, useEffect } from "react";
import {
    Container,
    Grid,
    Typography,
    Button,
    Modal,
    TextField,
    Card,
    CardContent,
    CardMedia,
    Box,
    IconButton,
    Snackbar,
    Alert,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from 'axios';
import { apiBaseUrl } from './Config';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Certificates = () => {
    const [certificates, setCertificates] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [selectedCertificate, setSelectedCertificate] = useState(null);
    const [formData, setFormData] = useState({ certificateName: "", certificateDesc: "", certificateImg: null });
    const [openAddModal, setOpenAddModal] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteCertificateId, setDeleteCertificateId] = useState(null);

    useEffect(() => {
        fetchCertificates();
    }, []);

    const fetchCertificates = async () => {
        const response = await axios.get(`${apiBaseUrl}/getCertificates`);
        setCertificates(response.data);
    };

    const handleEditClick = (certificate) => {
        setSelectedCertificate(certificate);
        setFormData({
            certificateName: certificate.name,
            certificateDesc: certificate.desc,
            certificateImg: null
        });
        setOpenModal(true);
    };

    const handleFormChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({ ...formData, [name]: files ? files[0] : value });
    };

    const handleFormSubmit = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('certificateId', selectedCertificate.id);
            formDataToSend.append('certificateName', formData.certificateName);
            formDataToSend.append('certificateDesc', formData.certificateDesc);
            formDataToSend.append('certificateImg', formData.certificateImg);

            await axios.put(`${apiBaseUrl}/updateCertificate`, formDataToSend);
            setOpenModal(false);
            fetchCertificates();
            showAlert('Certificate updated successfully', 'success');
        } catch (error) {
            showAlert('Error updating certificate', 'error');
        }
    };

    const handleAddClick = () => {
        setFormData({ certificateName: "", certificateDesc: "", certificateImg: null });
        setOpenAddModal(true);
    };

    const handleAddFormSubmit = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('certificateName', formData.certificateName);
            formDataToSend.append('certificateDesc', formData.certificateDesc);
            formDataToSend.append('certificateImg', formData.certificateImg);

            await axios.post(`${apiBaseUrl}/addCertificate`, formDataToSend);
            setOpenAddModal(false);
            fetchCertificates();
            showAlert('Certificate added successfully', 'success');
        } catch (error) {
            showAlert('Error adding certificate', 'error');
        }
    };

    const showAlert = (message, severity) => {
        setAlertMessage(message);
        setAlertSeverity(severity);
        setAlertOpen(true);
    };

    const handleDeleteClick = (certificateId) => {
        setDeleteCertificateId(certificateId);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            await axios.delete(`${apiBaseUrl}/deleteCertificate?id=${deleteCertificateId}`);
            setDeleteDialogOpen(false);
            fetchCertificates();
            showAlert('Certificate deleted successfully', 'success');
        } catch (error) {
            showAlert('Error deleting certificate', 'error');
        }
    };

    const isAddFormValid = formData.certificateName && formData.certificateDesc && formData.certificateImg;

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={4}>
                {certificates.map((certificate) => (
                    <Grid item key={certificate.id} xs={12} sm={6} md={4}>
                        <Card>
                            <CardMedia
                                component="img"
                                height="160"
                                image={certificate.imageUrl}
                                alt={certificate.name}
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    {certificate.name}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {certificate.desc}
                                </Typography>
                                <IconButton
                                    color="primary"
                                    sx={{ mt: 2 }}
                                    onClick={() => handleEditClick(certificate)}
                                >
                                    <EditIcon />
                                </IconButton>
                                <IconButton
                                    color="error"
                                    sx={{ mt: 2 }}
                                    onClick={() => handleDeleteClick(certificate.id)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <Button variant="contained" color="primary" onClick={handleAddClick} startIcon={<AddIcon />}>
                    Add New Certificate
                </Button>
            </Box>

            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        Edit Certificate
                    </Typography>
                    <TextField
                        margin="normal"
                        fullWidth
                        id="certificateName"
                        name="certificateName"
                        label="Certificate Name"
                        value={formData.certificateName}
                        onChange={handleFormChange}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        id="certificateDesc"
                        name="certificateDesc"
                        label="Certificate Description"
                        value={formData.certificateDesc}
                        onChange={handleFormChange}
                    />
                    <input
                        type="file"
                        id="docpicker"
                        accept=".png,.jpg,.jpeg"
                        name="certificateImg"
                        onChange={handleFormChange}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2 }}
                        onClick={handleFormSubmit}
                    >
                        Save
                    </Button>
                    <Button
                        variant="outlined"
                        color="error"
                        sx={{ mt: 2 }}
                        onClick={() => setOpenModal(false)}
                    >
                        Close
                    </Button>
                </Box>
            </Modal>

            <Modal
                open={openAddModal}
                onClose={() => setOpenAddModal(false)}
                aria-labelledby="add-modal-title"
                aria-describedby="add-modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography id="add-modal-title" variant="h6" component="h2">
                        Add New Certificate
                    </Typography>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="certificateName"
                        name="certificateName"
                        label="Certificate Name"
                        value={formData.certificateName}
                        onChange={handleFormChange}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="certificateDesc"
                        name="certificateDesc"
                        label="Certificate Description"
                        value={formData.certificateDesc}
                        onChange={handleFormChange}
                    />
                    <input
                        type="file"
                        id="add-docpicker"
                        accept=".png,.jpg,.jpeg"
                        name="certificateImg"
                        onChange={handleFormChange}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2 }}
                        onClick={handleAddFormSubmit}
                        disabled={!isAddFormValid}
                    >
                        Add
                    </Button>
                    <Button
                        variant="outlined"
                        color="error"
                        sx={{ mt: 2 }}
                        onClick={() => setOpenAddModal(false)}
                    >
                        Close
                    </Button>
                </Box>
            </Modal>

            <Snackbar
                open={alertOpen}
                autoHideDuration={3000}
                onClose={() => setAlertOpen(false)}
            >
                <Alert onClose={() => setAlertOpen(false)} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>

            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">Delete Certificate</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        Are you sure you want to delete this certificate?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default Certificates;
