import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./component/Login";
import Dashboard from "./component/Dashboard";
import { createContext, useReducer, useState } from "react";
import AboutUsForm from "./component/AboutUsForm";
import Products from "./component/Products";
import Reviews from "./component/Reviews";
import Certificates from "./component/Certificates";
import ProductsCategory from "./component/ProductsCategory";
import GalleryComponent from "./component/GalleryComponent";
import { Typography } from "@mui/material";
import { reducer, initialState } from './reducer/UseReducer';
import Logout from "./component/Logout";
export const UserContext = createContext();

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [component, setcomponent] = useState(
    <>
      <div className='text-center'>
        <h1 className='pt-5'>Welcome back</h1>
        {/* <p className="" style={{ fontSize: "24px" }}></p> */}
        <Typography color="primary" variant="h5">Damodarr Admin</Typography>
      </div>
    </>
  );
  return (
    <>
      <UserContext.Provider value={{ state, dispatch }}>
        <BrowserRouter>
          <Routes>
            {/* <Route element={<PrivateComponent />}> */}
            <Route path="/dashboard" element={<Dashboard from={component} />} />
            <Route path="/about" element={<Dashboard from={<AboutUsForm />} />} />
            <Route path="/gallery" element={<Dashboard from={<GalleryComponent />} />} />
            <Route path="/feedback" element={<Dashboard from={<Reviews />} />} />
            <Route path="/licence" element={<Dashboard from={<Certificates />} />} />
            <Route path="/products" element={<Dashboard from={<Products />} />} />
            <Route path="/productsCategory" element={<Dashboard from={<ProductsCategory />} />} />
            {/* </Route> */}
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="*" element={<Login />} />
          </Routes>
        </BrowserRouter>
      </UserContext.Provider>
    </>
  );
}

export default App;
